<template>
    <div class="tab-content">
        <div class="tab-header flexed">
            <div class="search-wrapper">
                <v-text-field
                    class="styled-field"
                    label="Search"
                    clearable
                    placeholder="Enter terms..."
                    @input="handleSearch" />
            </div>
        </div>
        <div class="table-wrapper">
            <platform-campaigns
                v-for="(platform, index) in filteredPlatforms"
                :key="platform"
                :search="search"
                :platform="platform"
                :index="index" />
        </div>
    </div>
</template>

<script>
import debounce from 'debounce';
import PlatformCampaigns from './PlatformCampaigns';
import { mapState } from 'vuex';

export default {
    name: 'Campaigns',
    components: {
        PlatformCampaigns
    },
    props: {
        platforms: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            search: '',
            managedCampaigns: []
        };
    },
    computed: {
        ...mapState({
            selectedCampaigns: (state) => state.playbookManagement.selectedCampaigns
        }),
        managingCampaigns() {
            return this.$store.state.playbookManagement.managingCampaigns;
        },
        filteredPlatforms() {
            return this.managingCampaigns ? this.managedCampaigns : this.platforms;
        }
    },
    mounted() {
        this.managedCampaigns = this.platforms.filter(platform => (
            this.selectedCampaigns[platform].length
        ));
    },
    methods: {
        handleSearch: debounce(function(input) {
            this.search = input;
        }, 400)
    }
};
</script>
